exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-corporate-tsx": () => import("./../../../src/pages/corporate.tsx" /* webpackChunkName: "component---src-pages-corporate-tsx" */),
  "component---src-pages-de-privacy-tsx": () => import("./../../../src/pages/de/privacy.tsx" /* webpackChunkName: "component---src-pages-de-privacy-tsx" */),
  "component---src-pages-de-terms-of-use-tsx": () => import("./../../../src/pages/de/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-de-terms-of-use-tsx" */),
  "component---src-pages-en-privacy-tsx": () => import("./../../../src/pages/en/privacy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-tsx" */),
  "component---src-pages-en-terms-of-use-tsx": () => import("./../../../src/pages/en/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-en-terms-of-use-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insurance-tsx": () => import("./../../../src/pages/insurance.tsx" /* webpackChunkName: "component---src-pages-insurance-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-website-terms-of-service-tsx": () => import("./../../../src/pages/website-terms-of-service.tsx" /* webpackChunkName: "component---src-pages-website-terms-of-service-tsx" */)
}

